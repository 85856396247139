.assignment-summary{
    font-size: 12px;
    color: #666666;
    margin-left: 8px;
}

.duties{
    font-size: 13px;
    margin-left: 24px;
}

a{
    font-size: 12px;
}

.assignment{
    margin-bottom: 8px;
}
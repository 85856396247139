.employment{

}

.employment:not(:last-child){
    border-bottom: 2px dotted #ccc;
    margin-bottom: 16px;
}

.job-title-header{
    margin-bottom: 8px;
}
body {
    font-family: Segoe UI, Verdana, Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

.container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #fff;
    --padding: 20px;
    padding: 40px 40px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
    color: #333;
}

p {
    color: #666;
}

.section {
    margin-bottom: 20px;
    border-bottom: 2px solid #ccc;
}

.section-header {
    border-bottom: 2px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.experience {
    margin-top: 12px;
    margin-left: 16px; margin-right: 16px;
}



ul {
    list-style-type: square;
    list-style-position: outside;
}

h4{
    color: #666666;
    font-weight: 600;
}
.personal{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img{
    border-radius: 50%;
}

img{
    border-radius: 50%;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.linkedin li{
    content: 'ldn';
}

.email li{
    content: 'eml';
}

.github li
{
    content: ''
}